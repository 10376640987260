import React from "react"
import { Layout } from "@/layout"
import { Width, Box, SEO, Heading, LegalText} from "@/components/index"

export default function Privacy() {
  return (
    <Layout headerType="simple" headerTheme="light" footerTheme="light">
      <SEO
        title="How to Allow Our Emails"
        description="Instructions on how to whitelist Sonora's emails"
      />
      <Width
        minheight="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        pt={["16.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Box
          height="100%"
          width={["100%", null, 6 / 12]}
          mx="auto"
          pt="6rem"
          pb="9rem"
        >
          <Heading level="1" textAlign="center" mb="2rem">
            How To Allow Our Emails
          </Heading>
          <LegalText>
            <p>
              To allow an email address just means you add them to your
              approved senders list. This tells your email client that you know
              this sender and trust them, which will keep emails from this
              contact at the top of your inbox and out of the junk folder. There
              are a few ways you can do this.
            </p>
            <h2>Add the address to your contacts:</h2>
            <p>
              Add the email address that is sending you email (for example:
              contact@learnwithsonora.com) to your Contact List. Most
              email providers will usually deliver email from addresses that are
              in your Contacts List.
            </p>
            <h2>
              If you find our emails in your "Promotions" Tab (Gmail users):
            </h2>
            <p>
              1.) Go to your Promotions tab and locate an email from us there
            </p>
            <p>
              2.) Simply drag this email over to the Primary tab of your inbox
            </p>
            <h2>Yahoo Mail Users:</h2>
            <p>
              When you open the email message, you should see a "+" symbol
              directly next to the <strong>From:</strong> and the{" "}
              <strong>Sender's Name.</strong> Click on the "+" symbol and then{" "}
              <strong>Add To Contacts.</strong> A pop-up should appear. Select{" "}
              <strong>Save.</strong>
            </p>
            <h2>Outlook 2003 and Later Users:</h2>
            <p>
              In the list view of your emails, locate an email from us. Right
              click on the email message in your inbox. Then select{" "}
              <strong>Junk E-mail</strong> from the contextual menu. The click
              on <strong>Add Send to Safe Senders</strong> List in that submenu.
            </p>
          </LegalText>
        </Box>
      </Width>
    </Layout>
  )
}
